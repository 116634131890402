.cover_layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.5);
}

.react_loading_skeleton {
  --base-color: rgba(0, 0, 0, 0);
  --highlight-color: rgba(255, 255, 255, 0.1);
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
}

.react_loading_skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
                  90deg,
                  var(--base-color) 0,
                  var(--highlight-color) 50%,
                  var(--base-color) 100%
  );
  background-position: calc(100% - 1200px);

  animation-name: react_loading_skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes react_loading_skeleton {
  100% {
    background-position: calc(100% + 1200px);
  }
}
