@use "@styles/variables.scss" as variable;

.wrapper {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .up {
    transform: rotate(180deg);
  }

  .down {
    transform: rotate(0deg);
  }

  .active {
    * {
      fill: variable.$primary;
    }
  }
}
