.loader {
  animation: load 1.2s infinite;
}

@keyframes load {
  0% {
    transform: rotate(0deg) scale(0.8);
    animation-timig-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
    animation-timig-function: cubic-bezier(0.215, 0.61, 0.335, 1);
  }
  100% {
    transform: rotate(360deg) scale(0.8);
  }
}
