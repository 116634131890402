@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.sorting {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 8px;
  height: 38px;
  font-size: 12px;
  border-radius: 4px;

  &.opened {
    background: #22231F;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 50%;
    pointer-events: none;
  }
}

.sort_icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease 0s;
  background: inherit;

  &_asc {
    transform: rotateX(180deg);
  }
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;

  &__button {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;

    height: 38px;
    line-height: 23px;
    color: variable.$primary-new;
    background: inherit;
  }

  &__content {
    position: absolute;
    padding: 8px;
    z-index: 1;
    top: 100%;
    right: 0;
    width: 236px;
    min-height: 84px;
    display: none;
    background: variable.$color-dark;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    border: 1px solid variable.$color-white;

    &.right {
      @include media("screen", "<=tablet") {
        left: 0;
        right: auto;
      }
    }

    &_show {
      display: block;
    }
  }

  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */

    display: block;
    width: 220px;
    text-align: left;
    padding: 8px 12px;
    color: variable.$color-white;
    background: variable.$color-dark;
    white-space: nowrap;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background-color: variable.$option-bg-active;
    }

    &_selected {
      color: variable.$color-bg;
      background-color: variable.$primary-new;
      font-weight: 600;

      &:hover {
        cursor: default;
        background-color: variable.$primary-new;
      }
    }
  }
}
